import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import TermsAndConditions from "../components/terms-and-conditions"


const TermsAndConditionsPage = () => (
    <Layout>
      <Seo title="Terms and Conditions" />
      <TermsAndConditions />
    </Layout>
  )
  
  export default TermsAndConditionsPage